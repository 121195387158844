import React from 'react'
import auth from '../utils/auth'

const Callback = () => {
  auth.handleAuthentication()

  return <p>Lade...</p>
}

export default Callback
